.flashcard {
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: .25rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: white;
  transform-style: preserve-3d;
  transition: 150ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0)); */
  background-color: #ddd;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, .25);
  font-family: "Segoe UI", sans-serif;
	/* font-size: 2rem; */
	color: #fff;
	/* text-transform: uppercase; */
	text-align: center;
  position: relative;
  margin: 10px;
}

.flashcard, .front, .back {
  /* width: 20rem; */
  height: 200px;
}

.flashcard:hover {
  background-color: white;
}

.front, .back {
	position: absolute;
	top: 0;
	left: 0;
	backface-visibility: hidden;
	overflow: auto;
	transition: transform .8s ease;
  text-align: center;
}
.back {
	transform: rotateY(180deg);
}
.flashcard.flip .front {
	transform: rotateY(-180deg);
}

.flashcard.flip .back {
	transform: rotateY(0deg);
}

.flashcard {
	perspective: 75rem;
}

.flashcard:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

/* .flashcard.flip {
  --rotate-y: 180deg;
} */
.flashcard .front {
  text-align: center;
}

.flashcard .front,
.flashcard .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
  text-align: center;
  width: 100%;
}

.flashcard-p {
  display: block;
  margin-bottom: 10px;
}

/* .flashcard .back {
  transform: rotateY(180deg);
} */

.flashcard-options {
  margin-top: .5rem;
}

.flashcard-option {
  margin-top: .25rem;
  color: #555;
  /* font-size: .75rem; */
}

.flashcard-option:first-child {
  margin-top: 0;
}


/* .flexbox-container::after {
  content: "";
  flex: auto;
} */

.intro {
  order: -1;
  /* align-self: flex-start; */
  flex-basis: 100%;
  grid-column-start: 1; 
  grid-column-end: 5;
}

.flex-item {
  flex-basis: 25%;
  text-align: center;
}

@media all and (max-width: 1125px) {
  /* CSS rules here for screens lower than 750px */
  .flexbox-container {
    display: flex;
    /* flex-direction: column; */
    flex-flow: column wrap;
    /* flex: 1 1 100%; */
    justify-content: center;
    /* column-gap: 2rem; */
  }
    .flex-item {
      /* flex-basis: 100%; */
      max-width: 400px;
      width: 100%;
      flex-basis: 200px;
    }
    /* .card-grid { */
      /* grid-template-columns: repeat(1, 100%); */
      /* grid-template-columns: max-content; */
      /* grid-template-columns: 1fr; */

    /* } */
  }

  .w-100 {
    min-width: 600px;
  }