@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css);
/* base styles */
/* every element with the "*" */
* {
  margin: 0;
  font-family: "Hind Siliguri";
  color: #333;
}
body {
  background-color: rgb(179, 179, 179);
}
b {
  color: #faa361;
}
.navbar {
  color: #fff;
  /* padding: 20px 10px; */
  display: flex;
  align-items: center; 
  /* vertically center! line 13 */
  /* max-width: 600px; */
  background-color: rgb(79, 79, 79);
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
#header {
  color: #eec232;
  font-size: x-large;
  /* padding-top: 8px; */
}
.navbar .login {
  margin-left: auto;
  /* shoots elements to the right bc parent displayed as flex */
}
.signin a {
  color: pink;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  color: #fff;
  font-size: 13pt;
}
.navbar a:hover {
  color: #faa361;
}
.content {
  max-width: 900px;
  min-width: 400px;
  width: 80%;
  margin: 40px auto;
  /* add auto above to "centralize" it */
  padding: 20px;
}

/* blog previews / list */
.blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
  border-radius: 10px;
  background-color: rgb(233, 233, 233);
  color: white;
  text-align: right;
}
.blog-preview:hover {
  box-shadow: 1px 3px 5px rgba(255, 255, 255, 0.1);
}
.blog-preview h2 {
  text-align: left;
  font-size: 20px;
  color: #f0ad4e;
  margin-bottom: 8px;
}
.blog-preview strong {
  color: #faa361;
}

.blog-preview a {
  text-decoration: none;
}

/* blog details page */
.blog-details h2 {
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 10px;
}
.blog-details div {
  margin: 20px 0;
}
.blog-details button {
  background: #f1356d;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* create new blog form */
.create {
  max-width: 700px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.create label {
  text-align: left;
  display: block;
}
.create h2 {
  font-size: 20px;
  color: #ff6f00;
  background-color: rgb(79, 79, 79);
  padding: 5px;
  /* text-shadow: 2px 2px 2px rgba(255, 239, 117, 0.63); */
  margin-bottom: 30px;
}
.create h2:hover {
  font-size: 20px;
  color: #ff6f00;
  background-color: rgb(225, 225, 225);
  padding: 5px;
  /* text-shadow: 2px 2px 2px rgba(255, 239, 117, 0.63); */
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: 0 2px 4px #eec232;
}


.create input, .create textarea, .create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.create button {
  background: #faa361;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
#back {
  background: #fad661;
  color: rgb(61, 61, 61);
  border: 0;
  margin: -30px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* home styles */

/* details */
.details-links {
  text-align: center;
}

.d-link {
  margin: 10px;
}
/* login signup */
input {
  min-width: 140px;
}
.card {
  background-color: rgba(255, 255, 255, 0.719);
  border-radius: 2%;
  padding: 5%;
  margin: 3px;
}
.card-body {
  background-color: none;
  width: 80%;
  margin: 0 auto;
}
.card button {
  background-color: #faa361;
  border: 1px solid #fff4eb;
}
.card button:hover {
  background-color: #eec232;
  border: 1px solid #fff4eb;
}
.card a {
  color: #faa361;
}
.card a:hover {
  color: #eec232;
  text-decoration: none;
}

a {
  color: #ffd468;
}
a:hover {
  color: #fff;
  text-decoration: none;
}

@media all and (max-width: 991px) {
  /* CSS rules here for screens lower than 750px */
    .link {
      display: block;
    }
    .content {
      /* max-width: 900px;
      min-width: 400px;
      width: 80%;
      margin: 40px auto; */
      /* add auto above to "centralize" it */
      max-width:-webkit-max-content;
      max-width:-moz-max-content;
      max-width:max-content;
      padding: 0;
      min-width: 100%;
    }
    /* .card-body {
      margin: 0
    } */
    .card {
      background-color: rgba(255, 255, 255, 0.719);
      border-radius: 2%;
      /* padding: 5%; */
      /* margin: 3px; */
    }
  }

  .home {
    width: 100%;
  }

  button {
    margin: 10px 0;
  }
  label {
    margin-top: 5px;
    margin-left: 5px;
  }

  .spinner-parent {
    text-align: center;
  }
  /* #spinner {
    margin: auto;
    width: 300px;
  } */

  /* progress bar styles */
.progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  background-color: #ffae00;
  color: white;
  cursor: pointer;
  transition-duration: .3s;
}
.custom-file-upload:hover {
  background-color: #ff951b;
}

/* image grid styles */
.card-grid {
  margin: 10px auto;
  display: grid;
  align-items: center;
  /* grid-template-columns: repeat(3, 250px); */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}
.grid{
  margin: 5px auto;
  display: grid;
  /* grid-template-columns: repeat(auto-fill); */
  grid-gap: 10px;  
}
.grid-wrap{
  /* overflow: auto; */
  height: 0;
  padding: 5% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.grid-item{
  min-width: 50%;
  min-height: 50%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}
.grid-item h1 {
  transition-duration: .3s;
}
.grid-item h1:hover {
  color: #fff8dc;
  cursor: pointer;
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop div{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}
.flashcard {
  /* display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: .25rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: white;
  transform-style: preserve-3d;
  transition: 150ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0)); */
  background-color: #ddd;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, .25);
  font-family: "Segoe UI", sans-serif;
	/* font-size: 2rem; */
	color: #fff;
	/* text-transform: uppercase; */
	text-align: center;
  position: relative;
  margin: 10px;
}

.flashcard, .front, .back {
  /* width: 20rem; */
  height: 200px;
}

.flashcard:hover {
  background-color: white;
}

.front, .back {
	position: absolute;
	top: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	overflow: auto;
	transition: transform .8s ease;
  text-align: center;
}
.back {
	transform: rotateY(180deg);
}
.flashcard.flip .front {
	transform: rotateY(-180deg);
}

.flashcard.flip .back {
	transform: rotateY(0deg);
}

.flashcard {
	perspective: 75rem;
}

.flashcard:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

/* .flashcard.flip {
  --rotate-y: 180deg;
} */
.flashcard .front {
  text-align: center;
}

.flashcard .front,
.flashcard .back {
  position: absolute;
  padding: 1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  text-align: center;
  width: 100%;
}

.flashcard-p {
  display: block;
  margin-bottom: 10px;
}

/* .flashcard .back {
  transform: rotateY(180deg);
} */

.flashcard-options {
  margin-top: .5rem;
}

.flashcard-option {
  margin-top: .25rem;
  color: #555;
  /* font-size: .75rem; */
}

.flashcard-option:first-child {
  margin-top: 0;
}


/* .flexbox-container::after {
  content: "";
  flex: auto;
} */

.intro {
  order: -1;
  /* align-self: flex-start; */
  flex-basis: 100%;
  grid-column-start: 1; 
  grid-column-end: 5;
}

.flex-item {
  flex-basis: 25%;
  text-align: center;
}

@media all and (max-width: 1125px) {
  /* CSS rules here for screens lower than 750px */
  .flexbox-container {
    display: flex;
    /* flex-direction: column; */
    flex-flow: column wrap;
    /* flex: 1 1 100%; */
    justify-content: center;
    /* column-gap: 2rem; */
  }
    .flex-item {
      /* flex-basis: 100%; */
      max-width: 400px;
      width: 100%;
      flex-basis: 200px;
    }
    /* .card-grid { */
      /* grid-template-columns: repeat(1, 100%); */
      /* grid-template-columns: max-content; */
      /* grid-template-columns: 1fr; */

    /* } */
  }

  .w-100 {
    min-width: 600px;
  }
